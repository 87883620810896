<template>
  <div  class="extended-forms">
    <div class="row mx-0">
      <div v-if="order?.content"  class="col-12 my-4">
        <div class="orderResult w-100 richText">
          <RichTextEditor v-if="order.content?.blog_post_html" v-model="order.content.blog_post_html" />
          <RichTextEditor v-else v-model="order.content" />
        </div>
      </div>
      <div v-else-if="isLoading" class="col-12 mt-2 mb-4">
        <label class="mb-4">{{ $t('ToolsResults.loading') }}</label>
        <paragraph-skeleton />
      </div>
      <div v-else class="col-12 py-lg">
        <EmptyContainer
          firstLine="No contents yet!"
          secondLine="Your AI generated contents will appear here."
        />
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import ParagraphSkeleton from "@/pages/Components/ParagraphSkeleton.vue";
import EmptyContainer from "@/pages/Components/EmptyContainer";
import RichTextEditor from '@/components/Inputs/RichTextEditor.vue';

export default {
  computed: {
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
    order() {
      return this.$store.getters.getOrder;
    },
  },
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    RichTextEditor,
    ParagraphSkeleton,
    EmptyContainer
  },
  data() {
    return {
      //
    };
  },
};
</script>
<style lang="scss">
.align-content {
  display: flex;
  align-items: center;
  justify-content: between;

  input {
    color: #000 !important;
  }

  @media screen and (max-width:768px) {
      flex-direction: column;
      gap: 20px;
      justify-content: unset;
      align-items: unset;
  }
}
</style>