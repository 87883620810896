<template>
    <div>
        <div class="seo_page__container" v-for="( content ,i) in order" :key="i">
            <div class="seo_page__url">
                <h3 class="mb-0">{{ content.link }}</h3>
            </div>
            <div class="seo_page__url_statistics">
                <div class="seo_page__container_item">
                    <h6>{{$t('Authority Domain')}}</h6>
                    <div class="seo_page__url_ratio_container">
                        <div class="" :class="ratioClasses(content.domain_authority)"></div>
                        <h2>{{ content.page_authority }}</h2>
                    </div>
                </div>
                <div class="seo_page__container_item">
                    <h6>{{$t('Linked domains')}}</h6>
                    <h2>{{ content.linking_domains }}</h2>
                </div>
                <div class="seo_page__container_item">
                    <h6>{{$t('Total of links')}}</h6>
                    <h2>{{ content.total_links }}</h2>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
  name: 'seo-domain-autority',
  data() {
    return {
        array: []
    }
  },
  computed: {
    order() {
      return JSON.parse(this.$store.getters.getOrder.content)
    }
  },
  methods: {
        ratioClasses(ratioValue){
            const elementClass = 'seo_page__url_ratio';
            let modifier = '--green';

            if(ratioValue < 30){
                modifier = '--red'
            }

            if(ratioValue >= 30 && ratioValue <= 50){
                modifier = '--orange'
            }

            return [elementClass, elementClass + modifier];
        },
  },
  mounted() {  }
};

</script>