var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.order?.title && _vm.order?.slug == 'seo_article')?_c('div',{staticClass:"orderTitle seo_article"},[_c('div',{staticClass:"gen-art-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.title),expression:"order.title"}],attrs:{"type":"text"},domProps:{"value":(_vm.order.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.order, "title", $event.target.value)}}})])]):_vm._e(),(_vm.task && _vm.task.product_type == 'ai' && _vm.order.status != 'accepted')?_c('div',[(
        ['waiting_queue', 'waiting_generation', 'processing'].includes(
          _vm.order.status,
        )
      )?_c('div',{staticClass:"text-center py-md"},[_c('EmptyContainer',{attrs:{"firstLine":_vm.task.title,"secondLine":"Your tasks will appear here once you have generated the AI copy"}}),_c('base-button',{staticClass:"btn__generate mt-3",attrs:{"type":"primary","loading":_vm.generateLoading ||
          ['waiting_queue', 'processing'].includes(_vm.order.status),"disabled":!_vm.task.assignee_id && _vm.user.role == 'user'},on:{"click":function($event){return _vm.generateContent()}}},[_vm._v(_vm._s(_vm.order.status == "waiting_queue" ? "Processing" : "Generate content"))])],1):_vm._e(),(['failed'].includes(_vm.order.status))?_c('div',{staticClass:"text-center py-md"},[_c('EmptyContainer',{attrs:{"contentClass":"failed-content","firstLine":"Something went wrong"}}),_c('base-button',{staticClass:"btn__generate mt-3",attrs:{"type":"primary","loading":_vm.generateLoading,"disabled":!_vm.task.assignee_id && _vm.user.role == 'user'},on:{"click":function($event){return _vm.generateContent()}}},[_vm._v("Try Again")])],1):_vm._e()]):_c('div',{staticClass:"orderResult w-100 richText",class:_vm.order?.product_key == 'blog_post_paragraphs'
        ? 'blog_post_paragraph_editor'
        : ''},[(_vm.order?.content && _vm.order?.status == 'accepted' && _vm.order?.product_key == 'yolo_blog_post_paragraphs')?_c('YoloBlogPostParagraphsFinalResult'):(_vm.order?.content && _vm.order?.status == 'accepted' && _vm.order?.product_key == 'yolo_copy_social')?_c('YoloCopySocialFinalResult'):_c('div',[(
          (_vm.contentComponentName &&
            _vm.order &&
            _vm.order?.content &&
            _vm.order?.status == 'accepted') ||
          _vm.task
        )?_c(_vm.contentComponentName,{tag:"component",attrs:{"disabled":_vm.task && _vm.task.assignee_id != _vm.user.id && _vm.user.role == 'user'},model:{value:(_vm.order.content),callback:function ($$v) {_vm.$set(_vm.order, "content", $$v)},expression:"order.content"}}):(
          (_vm.order && !_vm.order?.content) ||
          (_vm.order && _vm.order?.content && _vm.order?.status !== 'accepted')
        )?_c('p',[_vm._v(" Nothing to show ")]):_c('ParagraphSkeleton'),(_vm.order?.product_key == 'blog_post_paragraphs' && !_vm.savedImages)?_c('BlogPostParagraphsImageSection',{attrs:{"order":_vm.order}}):_vm._e(),(_vm.ragDocuments?.length > 0 || _vm.ragLinks?.length > 0)?_c('RagAdditionalSource',{attrs:{"documents":_vm.ragDocuments,"links":_vm.ragLinks}}):_vm._e(),_c('hr'),(_vm.savedImages.length > 0)?_c('BlogPostParagraphsGallery',{attrs:{"images":_vm.savedImages}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }