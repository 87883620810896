<template>
  <div class="extended-forms">
    <div class="row mx-0">
      <div v-if="order?.title && order.slug == 'seo_article'" class="col-md-12">
        <div class="gen-art-left">
          <input type="text" class="mb-0 mt-4" v-model="order.title" />
        </div>
      </div>
      <div v-if="order?.content" class="col-12 my-4">
        <div class="orderResult w-100 richText blog_post_paragraph_editor">
          <RichTextEditor v-if="order.content?.blog_post_html" v-model="order.content.blog_post_html" />
          <RichTextEditor v-else v-model="order.content" />
        </div>
      </div>
      <div v-else-if="isLoading" class="col-12 mt-2 mb-4">
        <label class="mb-4">{{ $t('ToolsResults.loading') }}</label>
        <paragraph-skeleton />
      </div>
      <div v-else class="col-12 mt-2 mb-4">
        <label>{{ $t('ToolsResults.nothinToShow') }}</label>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Option } from 'element-ui';
import ParagraphSkeleton from "@/pages/Components/ParagraphSkeleton.vue";
import RichTextEditor from '@/components/Inputs/RichTextEditor.vue';

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    RichTextEditor,
    ParagraphSkeleton,
  },
  computed: {
    isLoading() {
      return this.$store.getters.getIsLoading;
    },
    order() {
      return this.$store.getters.getOrder;
    },
    toolSlug() {
      return this.$route.params.slug
    },
    images() {
      return this.$store.getters.savedImages;
    },
  },
};
</script>
<style scoped>
.images-title {
  margin-bottom: 0;
}

.blog-images-gallery {
  display: flex;
  gap: 20px;
  margin: 24px 0;
}

.confirm-button-row {
  float: right;
}

.blog-img {
  width: 200px;
}

#images {
  padding: 2rem 0 4rem;
}
</style>
