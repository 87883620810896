<template>
    <div>
      <div>
        <h3 class="card-title ml-0 mt-4 font-weight-bold article_title">
          {{ order.content.main_title }}
        </h3>
      </div>
        <ContentEditor/>
        <ImagesGallery v-if="images" :images="images" />
        <MetadataViewer />
        <RagAdditionalSource
            v-if="ragDocuments.length > 0 || ragLinks.length > 0"
            :documents="ragDocuments"
            :links="ragLinks"
        />

        <div class="mt-4 d-flex">
            <base-button
                class="mr-2"
                @click="generateSocialPost"
            >
                Generate social post
            </base-button>
        </div>
    </div>
</template>

<script>
import ContentEditor from './components/ContentEditor.vue';
import ImagesGallery from './components/ImagesGallery.vue';
import MetadataViewer from './components/MetadataViewer.vue';
import RagAdditionalSource from "@/components/Inputs/RagAdditionalSource.vue";


export default {
    components: {
        ContentEditor,
        ImagesGallery,
        MetadataViewer,
        RagAdditionalSource,
    },
    computed: {
        order() {
            return this.$store.getters.getOrder
        },
        images() {
            return this.order?.json_data?.saved_images ?? this.$store.getters.savedImages ?? []
        },
        ragData() {
            return this.order?.json_data?.api_output?.retrieved_documents_metadata ?? this.order?.content?.retrieved_documents_metadata
        },
        ragDocuments() {
            return this.ragData?.filter((el) => el.url_type == "aws_url") ?? [];
        },
        ragLinks() {
            return this.ragData?.filter((el) => el.url_type == "standard_url") ?? [];
        },
    },
    methods: {
        generateSocialPost() {
            const id = this.order.order_detail_id

            this.$router.push(`/tools/yolo_copy_social?blog_article_source_order_id=${id}`)
        }
    }
}
</script>
<style scoped>
.article_title{
  font-size: 1.5rem;
}
</style>
