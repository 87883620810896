<script>
import LinkBuilderForm from "@/pages/Pages/ControlPanel/Workspace/WorkspaceSettings/LinkBuilderSettings/LinkBuilderForm.vue";
import { Dialog, Message, Table, TableColumn } from "element-ui";
import WebsiteCreateForm from "@/pages/Pages/ControlPanel/Workspace/WorkspaceSettings/WebsiteSettings/WebsiteCreateForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LinkBuilderSettings",
  components: {
    LinkBuilderForm,
    [Dialog.name]: Dialog,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      isLinkFormVisible: false,
      activeLink: {
        id: null,
        name: null,
        url: null,
        description: null,
      },
    };
  },
  methods: {
    ...mapActions([
      "createLinkSource",
      "fetchWorkspaceLinkSources",
      "updateLinkSource",
      "deleteLinkSource"
    ]),
    updateLink(link) {
      this.activeLink = link;
      this.isLinkFormVisible = true;
    },
    resetLink() {
      this.activeLink = {
        id: null,
        name: null,
        url: null,
        description: null,
      };
    },
    async handleSubmitForm(link) {
      try {
        if (link.id) {
          await this.updateLinkSource({
            ...link,
            workspace_id: this.selectedWorkspace.id,
          });
          Message.success("Link source created successfully");
        } else {
          await this.createLinkSource({
            ...link,
            workspace_id: this.selectedWorkspace.id,
          });
          Message.success("Link source created successfully");
        }
      } catch (e) {
        Message.error("An error occurred while saving the link source");
      } finally {
        this.isLinkFormVisible = false;
        this.resetLink();
      }
    },
    async deleteLink(id) {
      try {
        await this.deleteLinkSource(id);
        Message.success("Link source deleted successfully");
      } catch (e) {
        Message.error("An error occurred while deleting the link source");
      }
    }
  },
  computed: {
    ...mapGetters([
      "workspaceLinkSources",
      "selectedWorkspace",
      "workspaceLinkSourcesLoading"
    ]),
  },
  mounted() {
    if(this.selectedWorkspace) {
      this.fetchWorkspaceLinkSources(
        this.selectedWorkspace.id
      );
    }
  }
};
</script>

<template>
  <card class="website_panel">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h4 slot="header" class="title mb-0">Link Builder Settings</h4>
        <p slot="header" class="subtitle mb-0">
          Manage the web sources the AI uses for suggesting links in the ‘Link
          builder’ tool
        </p>
      </div>
      <base-button
        @click="isLinkFormVisible = true"
        native-type="submit"
        type="primary"
        class="btn-fill ml-3 my-0 py-3 px-3 btn_add_link"
      >
        Add Link
      </base-button>
    </div>

    <div class="row mt-3 mb-5">
      <div class="col-12">
        <div class="table-responsive table-website">
          <el-table :data="workspaceLinkSources" v-loading="workspaceLinkSourcesLoading">
            <el-table-column
              min-width="200"
              prop="Website name"
              :label="'Website name'"
              sortable
            >
              <template v-slot:default="{ row }">
                <label>{{ row.name }}</label>
              </template>
            </el-table-column>

            <el-table-column
              min-width="200"
              prop="Description"
              :label="'Description'"
              sortable
            >
              <template v-slot:default="{ row }">
                <label>{{ row.description }}</label>
              </template>
            </el-table-column>

            <el-table-column min-width="200" prop="Url" :label="'URL'" sortable>
              <template v-slot:default="{ row }">
                <label><a :href="row.url" target="_blank"> {{ row.url.slice(0, 30) }}</a></label>
              </template>
            </el-table-column>

            <el-table-column
              min-width="200"
              prop="Created On"
              :label="'Created On'"
              sortable
            >
              <template v-slot:default="{ row }">
                <label>{{ row.created_at }}</label>
              </template>
            </el-table-column>

            <el-table-column min-width="200" prop="Actions" :label="'Actions'">
              <template v-slot:default="{ row }">
                <base-button
                  @click="updateLink(row)"
                  icon
                  size="sm"
                  class="btn-simple mr-3"
                >
                  <div>
                    <i class="fa-solid fa-pen"></i>
                  </div>
                </base-button>
                <base-button
                  @click="deleteLink(row.id)"
                  icon
                  size="sm"
                  class="btn-simple mr-3"
                >
                  <div>
                    <i class="fa-solid fa-trash-xmark"></i>
                  </div>
                </base-button>
              </template>
            </el-table-column>
          </el-table>

          <el-dialog
            :visible.sync="isLinkFormVisible"
            class="dialog-sm"
            :before-close="
              () => {
                resetLink();
                isLinkFormVisible = false;
              }
            "
          >
            <LinkBuilderForm
              :link="activeLink"
              v-loading="workspaceLinkSourcesLoading"
              @exit-form="
                isLinkFormVisible = false;
                resetLink();
              "
              @submit-form="handleSubmitForm"
            />
          </el-dialog>
        </div>
      </div>
    </div>
  </card>
</template>

<style scoped></style>
