<template>
  <div class="row">
    <BreadCrumb>
      <!-- Dashboard -->
      <BreadCrumbItem style="display: inline-block">
        <router-link to="/dashboard" class="breadcrumb-link">
          {{ $t("sidebar.dashboard") }}
        </router-link>
      </BreadCrumbItem>
      <!-- Control Panel -->
      <BreadCrumbItem style="display: inline-block">
        <span class="breadcrumb-current">Workspace settings</span>
      </BreadCrumbItem>
    </BreadCrumb>

    <div class="col-12">
      <h1>Workspace settings</h1>
    </div>
    <div class="col-12">
      <div class="button_switch_panel-container">
        <button
          v-if="!isPersonal"
          @click="switchTab = 'member-settings'"
          class="button_switch_panel"
          :class="
            switchTab === 'member-settings'
              ? 'button_switch_panel--selected'
              : ''
          "
        >
          Member settings
        </button>
        <button
          @click="switchTab = 'bv-settings'"
          class="button_switch_panel"
          :class="
            switchTab === 'bv-settings' ||
            (isPersonal &&
              switchTab != 'website-settings' &&
              isPersonal &&
              switchTab != 'additional-sources-settings')
              ? 'button_switch_panel--selected'
              : ''
          "
        >
          Brand voices settings
        </button>
        <button
          @click="switchTab = 'website-settings'"
          class="button_switch_panel"
          :class="
            switchTab === 'website-settings'
              ? 'button_switch_panel--selected'
              : ''
          "
        >
          Websites settings
        </button>
        <button
          v-if="this.$store.getters.getUserExtraInfo.has_additional_sources"
          @click="switchTab = 'additional-sources-settings'"
          class="button_switch_panel"
          :class="
            switchTab === 'additional-sources-settings'
              ? 'button_switch_panel--selected'
              : ''
          "
        >
          Additional sources settings
        </button>
        <button
          v-if="!isPersonal"
          @click="switchTab = 'link-builder-settings'"
          class="button_switch_panel"
          :class="
            switchTab === 'link-builder-settings'
              ? 'button_switch_panel--selected'
              : ''
          "
        >
          Link Builder settings
        </button>
      </div>
    </div>

    <transition name="tab-fade">
      <div v-if="switchTab == 'member-settings' && !isPersonal" class="col-12">
        <WorkspaceMemberSettings />
      </div>
    </transition>
    <transition name="tab-fade">
      <div
        v-if="
          switchTab == 'bv-settings' ||
          (isPersonal &&
            switchTab != 'website-settings' &&
            isPersonal &&
            switchTab != 'additional-sources-settings')
        "
        class="col-12"
      >
        <BrandVoice />
      </div>
    </transition>

    <transition name="tab-fade">
      <div v-if="switchTab == 'website-settings'" class="col-12">
        <WebsiteSettings />
      </div>
    </transition>

    <transition name="tab-fade">
      <div
        v-if="
          switchTab == 'additional-sources-settings' &&
          this.$store.getters.getUserExtraInfo.has_additional_sources
        "
        class="col-12"
      >
        <RagAdditionalSources />
      </div>
    </transition>

    <transition name="tab-fade">
      <div
        v-if="switchTab === 'link-builder-settings' && !isPersonal"
        class="col-12"
      >
        <LinkBuilderSettings />
      </div>
    </transition>
  </div>
</template>
<script>
import WorkspaceMemberSettings from "./WorkspaceMemberSettings/WorkspaceMemberSettings.vue";
import BrandVoice from "./BrandVoiceSettings/BrandVoice.vue";
import WebsiteSettings from "./WebsiteSettings/WebsiteSettings.vue";
import BreadCrumb from "@/components/Breadcrumb/Breadcrumb";
import BreadCrumbItem from "@/components/Breadcrumb/BreadcrumbItem";
import LinkBuilderSettings from "@/pages/Pages/ControlPanel/Workspace/WorkspaceSettings/LinkBuilderSettings/LinkBuilderSettings.vue";
import RagAdditionalSources from "./RagAdditionalSources/RagAdditionalSources.vue";
export default {
  components: {
    WorkspaceMemberSettings,
    BreadCrumb,
    BreadCrumbItem,
    BrandVoice,
    WebsiteSettings,
    LinkBuilderSettings,
    RagAdditionalSources,
  },
  data() {
    return {
      switchTab: "member-settings",
    };
  },
  computed: {
    isPersonal() {
      return this.$store.getters.selectedWorkspace.id == null;
    },
  },
};
</script>
