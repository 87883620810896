<script>
export default {
  name: "Stack",
  props: {
    gap: {
      type: String,
      default: "1rem",
    },
  },
};
</script>

<template>
<div class="stack" :style="{
  '--gap': gap
}">
  <slot></slot>
</div>
</template>

<style scoped>
.stack {
  --gap: 1rem;
  display: flex;
  flex-flow: column nowrap;
  gap: var(--gap);
}
</style>
