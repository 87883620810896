<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { BaseInput } from "@/components/index";
import { Select, Option, Message } from "element-ui";
import Stack from "@/components/Containers/Stack.vue";
import debounce from "lodash";

export default {
  name: "LinkBuilderSettings",
  props: {
    workspaceLinkSources: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    Stack,
    BaseInput,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters([
      "getKeyword",
      "getOrder",
      "getOccurrencesNumber",
      "getSource",
      "getApply",
      "getSelection",
      "getErrors",
      "selectedWorkspace",
      "getEditingArea",
      "getOccurrences",
      "workspaceLinkSourcesLoading",
    ]),
    source: {
      get() {
        return this.getSource;
      },
      set(value) {
        this.setSource(value);
      },
    },
    apply: {
      get() {
        return this.getApply;
      },
      set(value) {
        this.setApply(value);
      },
    },
    keyword: {
      get() {
        return this.getKeyword;
      },
      set(value) {
        this.setKeyword(value);
      },
    }
  },
  methods: {
    ...mapMutations([
      "setKeyword",
      "setSource",
      "setApply",
      "setSelection",
      "setOccurrences"
    ]),
    ...mapActions([
      "updateKeyword",
      "fetchWorkspaceLinkResult",
    ]),

    handleSetKeyword() {
      this.updateKeyword(this.keyword);
    },

    deactivateOnSelectionStrategy() {
      this.setSelection(null);
    },

    async fetchSearchResult() {
      if(this.keyword === null || this.keyword === "") {
        Message.warning("Please enter a keyword to search for");
        return;
      }

      try {
        await this.fetchWorkspaceLinkResult({
          workspaceId: this.selectedWorkspace.id,
          keyword: this.keyword,
          source: this.source,
        });
      } catch (e) {
        Message.error("An error occurred while fetching the search result");
      }
    },

    findRangesInNode(node, ranges, word) {
      if(node.nodeType === 3) {
        const text = node.textContent;
        const occurrencesGlobal = text.matchAll(new RegExp(word, 'gi'));
        occurrencesGlobal.forEach((occurrence) => {
            const range = document.createRange();
            range.setStart(node, occurrence.index);
            range.setEnd(node, occurrence.index + word.length);
            ranges.push(range);
        })
      }
      if(node.nodeType !== 3) {
        if(node.nodeType === 1 && node.tagName === "A") {
          if (node.innerText.match(new RegExp(word, 'i'))) {
            ranges.push(node)
          }
        } else {
          if (
            node.nodeType === 1 &&
            node.tagName !== "H1" &&
            node.tagName !== "H2" &&
            node.tagName !== "H3"
          ) {
            node.childNodes.forEach((child) => {
              this.findRangesInNode(child, ranges, word);
            });
          }
        }
      }
    },
  },
  watch: {
    keyword: {
      handler: function(newVal, oldVal) {
        if(newVal === null || newVal === "") {
          this.setOccurrences([]);
          return;
        }
        this.setOccurrences([]);
        let ranges = [];
        this.findRangesInNode(this.getEditingArea, ranges, newVal);
        this.setOccurrences(ranges)
      },
    }
  },
};
</script>

<template>
  <Stack>
    <div>
      <div>
        <label>{{ $t("linkBuilder.builder.source.label") }}</label>
      </div>
      <ValidationProvider rules="required">
        <el-select
          :popper-append-to-body="false"
          class="select-primary"
          size="medium"
          :placeholder="$t('linkBuilder.builder.source.values.all')"
          v-model="source"
          multiple
        >
          <el-option
            v-for="sourceLink in workspaceLinkSources"
            class="select-primary"
            :value="sourceLink.id"
            :label="sourceLink.url"
            :key="sourceLink.url"
          />
        </el-select>
      </ValidationProvider>
    </div>

    <ValidationProvider rules="required">
      <div>
        <label>{{ $t("linkBuilder.builder.search.label") }}</label>
      </div>
      <div class="input_with_btn mb-1 align-items-start">
        <BaseInput
          :placeholder="$t('linkBuilder.builder.search.placeholder')"
          type="text"
          v-model="keyword"
          :optional="true"
          @keyup="deactivateOnSelectionStrategy"
          class="mb-0"
          :error="getErrors.keyword"
          :style="{ cursor: 'pointer' }"
        />
        <base-button icon size="sm" class="m-0" type="button" @click="fetchSearchResult">
          <div>
            <!-- <i class="fa-solid fa-link"></i> -->
            <img src="/img/icons/linkBuilder/custom-link-builder-icon-white.svg" alt="">
          </div>
        </base-button>
      </div>
    </ValidationProvider>

      <p v-if="getKeyword !== null" class="font-body-xs sub-label">
        Found <b>{{ getOccurrencesNumber }}</b> times in your text
      </p>
    </div>

    <div>
      <div>
        <label>{{ $t("linkBuilder.builder.apply.label") }}</label>
      </div>
      <ValidationProvider rules="required">
        <el-select
          v-model="apply"
          class="select-primary"
          size="medium"
        >
          <el-option
            class="select-primary text-capitalize"
            value="all"
            :label="$t('linkBuilder.builder.apply.values.all')"
          />
          <el-option
            v-if="getSelection !== null"
            class="select-primary text-capitalize"
            value="selected"
            :label="$t('linkBuilder.builder.apply.values.selected')"
          />
        </el-select>
      </ValidationProvider>
    </div>
  </Stack>
</template>

<style scoped>
.sub-label {
  text-align: end;
}
</style>
