<template>
  <div class="row mt-3 mb-5">
    <div class="col-12">
      <div class="table-responsive table-text-to-image">
        <a href="#" @click.prevent="resetDefault()">Reset default</a>

        <el-table :data="brandVoices" v-loading="loading" style="width: 100%">
          <!-- Default -->
          <el-table-column prop="Default" label="Default" sortable>
            <template #default="scope">
              <input
                type="radio"
                :checked="scope.row.default"
                name="default"
                @change="setAsDefault(scope.row)"
                :disabled="!scope.row.active"
              />
            </template>
          </el-table-column>

          <!-- Name -->
          <el-table-column
            min-width="200"
            prop="Voice Name"
            :label="$t('brandVoices.voiceName')"
            sortable
          >
            <template #default="scope">
              {{ scope.row.name }}<i class="ml-2 fa-solid mr-1 fa-star"></i>
            </template>
          </el-table-column>

          <!-- Type -->
          <el-table-column
            min-width="200"
            prop="Voice Type"
            :label="'Type'"
            align="center"
            sortable
          >
            <template #default="scope">
              {{ scope.row.type }}
            </template>
          </el-table-column>

          <!-- Created At -->
          <el-table-column
            min-width="200"
            prop="Created on"
            :label="$t('generic.createdOn')"
            align="center"
            sortable
          >
            <template #default="scope">
              {{ formatDate(scope.row.created_at) }}
            </template>
          </el-table-column>

          <!-- On/Off -->
          <el-table-column
            :min-width="135"
            align="right"
            :label="'On/Off'"
            sortable
          >
            <template #default="scope">
              <el-switch
                v-model="scope.row.active"
                @change="toggleBrandVoice(scope.row.id)"
              ></el-switch>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Switch } from "element-ui";
import { formatDate } from "@/util/date";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
  },
  data() {
    return {
      BvFormVisible: false,
      loading: true,
    };
  },
  computed: {
    brandVoices() {
      return this.$store.getters.getBrandVoices /* .map(bv => ({
        ...bv,
        workspace_default: bv.default
      })) */;
    },
    workspaceId() {
      return this.$route.params?.id ?? this.$store.getters.selectedWorkspace.id;
    },
  },
  methods: {
    formatDate,

    async setAsDefault(brandVoice) {
      this.loading = true;
      await this.$store
        .dispatch("updateBrandVoice", {
          ...brandVoice,
          default: true,
        })
        .then(() => (this.loading = false));
    },

    async resetDefault() {
      this.loading = true;
      await this.$store
        .dispatch("resetDefault", this.workspaceId)
        .then(() => (this.loading = false));
    },

    toggleBrandVoice(brandVoiceId) {
      console.log(brandVoiceId);
      this.$store.dispatch("toggleBrandVoice", {
        id: this.workspaceId,
        brand_voice_id: brandVoiceId,
      });
    },
  },
  mounted() {
    this.$store
      .dispatch("fetchBrandVoices", this.workspaceId)
      .then(() => (this.loading = false));
  },
};
</script>

<style scoped>
.pagination-select,
.search-input {
  width: 200px;
}

::v-deep .swal2-icon-content {
  font-size: inherit !important;
}
::v-deep .el-switch .el-switch__core {
  border-color: transparent;
  background: #dcdfe6;
  width: 44px !important;
  transition: all 0.3s;
}

::v-deep .el-switch.is-checked .el-switch__core {
  border-color: transparent;
  background: linear-gradient(
    107deg,
    #034a98 6.12%,
    #0e173f 44.98%,
    #321979 85.83%
  );
}
</style>
