<template>
  <div>
    <hr v-if="links.length > 0 || documents.length > 0" />
    <div v-if="links.length > 0">
      <h3 class="mb-3">
        <i style="width: 30px" class="fa-solid fa-link"></i> Source links
      </h3>
      <div class="links mb-3">
        <div v-for="(link, index) in links" :key="index">
          <a :href="link.url" target="_blank" class="text-primary mr-2 ml-2">{{
            link.file_name
          }}</a>
          <span v-if="index < links.length - 1"> - </span>
        </div>
      </div>
    </div>
    <div v-if="documents.length > 0">
      <h3 class="mb-3">
        <i style="width: 30px" class="fa-regular fa-file"></i> Source documents
      </h3>
      <div class="documents">
        <div v-for="(doc, index) in documents" :key="index">
          <a :href="doc.url" target="_blank" class="text-primary mr-2 ml-2">{{
            doc.file_name
          }}</a>
          <span v-if="index < documents.length - 1"> - </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
.text-primary,
.text-primary:hover {
  color: #007bff !important;
}
</style>
