<script>
import { mapMutations } from "vuex";

export default {
  name: "SelectableText",
  props: {
    text: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selection: null,
      selectedText: null,
      allOccurrencesRanges: [],
      selectedTextLength: 0,
      range: null,
    };
  },
  methods: {
    ...mapMutations(["setEditingArea", "setOccurrences"]),
    showTooltipInPosition(selection) {
      const selectedTextRect = selection.getBoundingClientRect();
      const selectableAreaRect =
        this.$refs.selectableArea.getBoundingClientRect();
      const tooltipTop = selectedTextRect.y - selectableAreaRect.y - 40;
      const tooltipLeft = selectedTextRect.x - selectableAreaRect.x;

      this.$refs.tooltipAction.style.setProperty("--y", `${tooltipTop}px`);
      this.$refs.tooltipAction.style.setProperty("--x", `${tooltipLeft}px`);
    },
    handleSelection() {
      const selection = window.getSelection();
      this.allOccurrencesRanges = [];

      if (selection.toString().length === 0 || selection.rangeCount === 0) {
        this.selectedText = null;
        return;
      }

      try {
        this.selectedText = selection.getRangeAt(0);
        this.selectedTextLength = this.selectedText.toString().length;
        this.showTooltipInPosition(this.selectedText);
      } catch (_) {
        this.selectedText = null;
      }
    },
    findRangesInNode(node, ranges, word) {
      if (node.nodeType === 3) {
        const text = node.textContent;
        const occurrencesGlobal = text.matchAll(new RegExp(word, "gi"));
        occurrencesGlobal.forEach((occurrence) => {
          const range = document.createRange();
          range.setStart(node, occurrence.index);
          range.setEnd(node, occurrence.index + word.length);
          ranges.push(range);
        });
      }
      if (node.nodeType !== 3) {
        if (node.nodeType === 1 && node.tagName === "A") {
          if (node.innerText.match(new RegExp(word, "i"))) {
            ranges.push(node);
          }
        } else {
          if (
            node.nodeType === 1 &&
            node.tagName !== "H1" &&
            node.tagName !== "H2" &&
            node.tagName !== "H3"
          ) {
            node.childNodes.forEach((child) => {
              this.findRangesInNode(child, ranges, word);
            });
          }
        }
      }
    },
    handleTooltipClick() {
      let word = this.selectedText.toString();
      let ranges = [];
      console.log(word);
      this.findRangesInNode(this.$refs.selectableArea, ranges, word);
      this.$emit("text-selected", {
        range: this.selectedText,
        occurrences: ranges,
      });
      this.selectedText = null;
    },
  },
  mounted() {
    this.$refs.selectableArea.addEventListener("mouseup", this.handleSelection);
    this.setEditingArea(this.$refs.selectableArea);
  },
};
</script>

<template>
  <div style="position: relative; margin-top: 2rem">
    <transition name="fade">
      <div
        class="tooltip-action"
        v-show="selectedText && selectedTextLength > 0"
        ref="tooltipAction"
      >
        <button @click="handleTooltipClick">Find links</button>
      </div>
    </transition>
    <div
      class="selectable-text"
      v-html="text"
      ref="selectableArea"
      id="selectableArea"
    ></div>
  </div>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.white-content > .selectable-text > p {
  color: var(--gray-placeholder) !important;
}

.selectable-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 2em;
  }

  position: relative;
  a {
    text-decoration: underline !important;
  }
}

.tooltip-action {
  z-index: 2000;
  position: absolute;
  --x: 0;
  --y: 0;
  top: var(--y);
  left: var(--x);
  background-color: var(--blu-accent);
  border-radius: 200px;
  color: var(--white);
  padding: 0.2rem 0.3rem;

  & > button {
    font-weight: bold;
    color: inherit;
    background: none;
    border: none;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--blu-accent) transparent transparent transparent;
  }
}
</style>
