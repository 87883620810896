<template>
  <div class="row mt-3 mb-5">
    <div class="col-12">
      <div class="table-responsive table-website">

        <el-table :data="websites" v-loading="loading">

          <!-- Website name -->
          <el-table-column min-width="200" prop="Website name" :label="'Website name'" sortable>
            <template #default="scope">
              <label>{{ scope.row.name }}</label>
            </template>
          </el-table-column>

          <!-- Description -->
          <el-table-column min-width="250" prop="Description" :label="'Description'" align="center" sortable>
            <template #default="scope">
              {{scope.row.desc}}
            </template>
          </el-table-column>

          <!-- url -->
          <el-table-column min-width="250" prop="Url" :label="'Url'" align="center" sortable>
            <template #default="scope">
              {{ scope.row.host_url }}
            </template>
          </el-table-column>

          <!-- Created At -->
          <el-table-column min-width="200" prop="Created on" :label="'Created on'" align="center" sortable>
            <template #default="scope">
              {{ formatDate(scope.row.created_at) }}
            </template>
          </el-table-column>

          <!-- Actions -->
          <el-table-column :min-width="135" align="right" :label="'Actions'" class="mr-3">
            <template #default="scope">
              <!-- <el-tooltip content="Edit Website"  :open-delay="300" placement="top"> -->
                <base-button @click="updateWebsite(scope.row)" icon size="sm" class="btn-simple mr-3">
                  <div>
                    <i class="fa-solid fa-pen"></i>
                  </div>
                </base-button>
              <!-- </el-tooltip> -->

              <!-- <el-tooltip content="Delete Website"  :open-delay="300" placement="top"> -->
              <el-popconfirm
                confirm-button-text='Confirm'
                cancel-button-text='Cancel'
                icon=""
                icon-color="red"
                title="Are you sure you want to delete this?"
                @confirm="deleteWebsite(scope.row)"
              >
              <base-button slot="reference" icon size="sm" class="btn-simple">
                <div>
                  <i class="fa-solid fa-trash-xmark"></i>
                </div>
              </base-button>
             </el-popconfirm>
            <!-- </el-tooltip> -->

            </template>
          </el-table-column>

        </el-table>

      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Tooltip, Popconfirm, Button } from 'element-ui';
import { formatDate } from '@/util/date';
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Popconfirm.name]: Popconfirm,
    [Button.name]: Button // used in popconfirm

  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    websites() {
      return this.$store.getters.getWebsites
    },
    workspaceId() {
      return this.$route.params?.id ?? this.$store.getters.selectedWorkspace.id;
    }
  },
  methods: {
    formatDate,

    updateWebsite(website) {
      this.$emit('update', website);
    },

    async deleteWebsite(website) {
      this.loading = true;
      await this.$store.dispatch("deleteWebsite", website.id).then(() => this.loading = false);
    },
  },
  mounted() {
    this.$store.dispatch("fetchWebsites", this.workspaceId).then(() => this.loading = false);
  }
};
</script>

<style lang="scss">
  .table-website th.is-right .cell {
    padding-right: 40px !important;
  }
</style>
