var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('BreadCrumb',[_c('BreadCrumbItem',{staticStyle:{"display":"inline-block"}},[_c('router-link',{staticClass:"breadcrumb-link",attrs:{"to":"/dashboard"}},[_vm._v(" "+_vm._s(_vm.$t("sidebar.dashboard"))+" ")])],1),_c('BreadCrumbItem',{staticStyle:{"display":"inline-block"}},[_c('span',{staticClass:"breadcrumb-current"},[_vm._v("Workspace settings")])])],1),_vm._m(0),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"button_switch_panel-container"},[(!_vm.isPersonal)?_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab === 'member-settings'
            ? 'button_switch_panel--selected'
            : '',on:{"click":function($event){_vm.switchTab = 'member-settings'}}},[_vm._v(" Member settings ")]):_vm._e(),_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab === 'bv-settings' ||
          (_vm.isPersonal &&
            _vm.switchTab != 'website-settings' &&
            _vm.isPersonal &&
            _vm.switchTab != 'additional-sources-settings')
            ? 'button_switch_panel--selected'
            : '',on:{"click":function($event){_vm.switchTab = 'bv-settings'}}},[_vm._v(" Brand voices settings ")]),_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab === 'website-settings'
            ? 'button_switch_panel--selected'
            : '',on:{"click":function($event){_vm.switchTab = 'website-settings'}}},[_vm._v(" Websites settings ")]),(this.$store.getters.getUserExtraInfo.has_additional_sources)?_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab === 'additional-sources-settings'
            ? 'button_switch_panel--selected'
            : '',on:{"click":function($event){_vm.switchTab = 'additional-sources-settings'}}},[_vm._v(" Additional sources settings ")]):_vm._e(),(!_vm.isPersonal)?_c('button',{staticClass:"button_switch_panel",class:_vm.switchTab === 'link-builder-settings'
            ? 'button_switch_panel--selected'
            : '',on:{"click":function($event){_vm.switchTab = 'link-builder-settings'}}},[_vm._v(" Link Builder settings ")]):_vm._e()])]),_c('transition',{attrs:{"name":"tab-fade"}},[(_vm.switchTab == 'member-settings' && !_vm.isPersonal)?_c('div',{staticClass:"col-12"},[_c('WorkspaceMemberSettings')],1):_vm._e()]),_c('transition',{attrs:{"name":"tab-fade"}},[(
        _vm.switchTab == 'bv-settings' ||
        (_vm.isPersonal &&
          _vm.switchTab != 'website-settings' &&
          _vm.isPersonal &&
          _vm.switchTab != 'additional-sources-settings')
      )?_c('div',{staticClass:"col-12"},[_c('BrandVoice')],1):_vm._e()]),_c('transition',{attrs:{"name":"tab-fade"}},[(_vm.switchTab == 'website-settings')?_c('div',{staticClass:"col-12"},[_c('WebsiteSettings')],1):_vm._e()]),_c('transition',{attrs:{"name":"tab-fade"}},[(
        _vm.switchTab == 'additional-sources-settings' &&
        this.$store.getters.getUserExtraInfo.has_additional_sources
      )?_c('div',{staticClass:"col-12"},[_c('RagAdditionalSources')],1):_vm._e()]),_c('transition',{attrs:{"name":"tab-fade"}},[(_vm.switchTab === 'link-builder-settings' && !_vm.isPersonal)?_c('div',{staticClass:"col-12"},[_c('LinkBuilderSettings')],1):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('h1',[_vm._v("Workspace settings")])])
}]

export { render, staticRenderFns }