<template>
    <div class="ai_chat">
		<div v-for="chat in orderAiChat">
			<div class="gen-chat-list">
				<span class="c-user"><img src="/img/icons/c-icon.png" width="20" alt="" /></span>
				<div class="c-chat" v-html="chat?.title"></div>
			</div>
			<div class="gen-chat-list">
				<span class="c-user"><img src="/img/icons/m-logo.png" width="20" alt="" /></span>
				<div class="c-chat">
					<div :id="'htmlContent' + index" v-html="chat?.content"></div>
				</div>
			</div>
		</div>	
    </div>

</template>

<script>
import axios from "@/axios";

export default {
  name: 'ai-chat',
  data() {
    return {
      orderAiChat: []
    }
  },
  computed: {
    order() {
			return this.$store.getters.getOrder;		
		},
  },
  methods: {
    async fetchAiChatOrder() {
        // Get the currently authenticated user
        const user = this.$store.getters.getUser;

        if (user) {
          const queryParams = {
            user_id: user.id,
            order_detail_id: this.order.order_detail_id,
            workspace_id: this.$store.getters.selectedWorkspace?.id,
            product_key: 'ai_chat'
          };
          // Fetch orders based on the specified order type
          let response;
          response = await axios.get("services/orders/show", {
            params: queryParams,
          });
          console.log(response);
          this.orderAiChat = response.data.data.orders;
      }
      // Throw an error if the required parameters are not provided or if the order retrieval process fails
      throw new Error("Failed to fetch order");
    },
  },
  mounted() { 
    this.fetchAiChatOrder()
   }
};

</script>