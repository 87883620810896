<template>
	<base-button class="mt-3 w-100 btn__generate" native-type="submit" type="primary"
		:class="['btn__filled', isDisabled ? 'btn__not_filled' : '']" :disable="isDisabled" @click="emitClick">
		<i v-if="icon" :class="icon"></i> {{ text }}
	</base-button>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			default: '',
		},
		icon: {
			type: String,
		},
		isDisabled: {
			type: Boolean,
			default: true,
		}
	},
	methods: {
		emitClick() {
			this.$emit('click');
		}
	}
};
</script>
