<template>
    <div>
        <ContentEditor/>
        <CopySocialImagesGallery v-if="images" :images="images" />
        <RagAdditionalSource
            v-if="ragDocuments.length > 0 || ragLinks.length > 0"
            :documents="ragDocuments"
            :links="ragLinks"
        />
    </div>
</template>

<script>
import ContentEditor from './components/CopySocialContentEditor.vue';
import CopySocialImagesGallery from './components/CopySocialImagesGallery.vue';
import RagAdditionalSource from "@/components/Inputs/RagAdditionalSource.vue";

export default {
    components: {
        ContentEditor,
        CopySocialImagesGallery,
        RagAdditionalSource,
    },
    computed: {
        order() {
            return this.$store.getters.getOrder
        },
        images() {
            return this.order?.json_data?.saved_images ?? this.$store.getters.savedImages ?? []
        },
        ragData() {
            return this.order?.json_data?.api_output?.retrieved_documents_metadata ?? this.order?.retrieved_documents_metadata
        },
        ragDocuments() {
            return this.ragData?.filter((el) => el.url_type == "aws_url") ?? [];
        },
        ragLinks() {
            return this.ragData?.filter((el) => el.url_type == "standard_url") ?? [];
        },
    },
}
</script>