<script>
export default {
  name: "Paragraph",
  props: {
    text: {
      type: String,
      required: false,
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
  }
};
</script>

<template>
  <p v-if="!isHtml" class="font-body-s">
    <slot></slot>
  </p>
  <p v-else class="font-body-s" v-html="text"></p>
</template>

<style scoped lang="scss">
p {
  color: var(--white);
  word-break: normal;
}
.white-content p {
  color: var(--gray-description) !important;
}
p > a {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--blu-accent);
  text-decoration: underline;
  display: inline;
}
</style>
