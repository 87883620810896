<script>
export default {
  name: "SuggestedLink",
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
};
</script>

<template>
  <div class="suggested-link">
    <h4 class="font-body-xs">{{ title }}</h4>
    <p class="font-body-xs">{{ description }}</p>
    <span class="link">{{ url }}</span>
  </div>
</template>

<style scoped lang="scss">
.suggested-link {
  &:hover {
    border-color: var(--blu-accent);
  }

  cursor: pointer;
  border: 2px solid #d8d8d8;
  border-radius: 8px;
  padding: 1rem;

  & > .link {
    color: var(--blu-accent);
  }

  & > h4 {
    color: var(--blu-dark);
  }

  & > p {
    color: var(--blu-whale);
  }
}
</style>
