<template>
  <div>
    <div
      v-if="order?.title && order?.slug == 'seo_article'"
      class="orderTitle seo_article"
    >
      <div class="gen-art-left">
        <input type="text" v-model="order.title" />
      </div>
    </div>

    <!-- generate button -->
    <div v-if="task && task.product_type == 'ai' && order.status != 'accepted'">
      <!-- waiting queue task-->
      <div
        v-if="
          ['waiting_queue', 'waiting_generation', 'processing'].includes(
            order.status,
          )
        "
        class="text-center py-md"
      >
        <EmptyContainer
          :firstLine="task.title"
          secondLine="Your tasks will appear here once you have generated the AI copy"
        ></EmptyContainer>
        <base-button
          type="primary"
          class="btn__generate mt-3"
          :loading="
            generateLoading ||
            ['waiting_queue', 'processing'].includes(order.status)
          "
          :disabled="!task.assignee_id && user.role == 'user'"
          @click="generateContent()"
          >{{
            order.status == "waiting_queue" ? "Processing" : "Generate content"
          }}</base-button
        >
      </div>

      <!-- failed task-->
      <div v-if="['failed'].includes(order.status)" class="text-center py-md">
        <EmptyContainer
          contentClass="failed-content"
          firstLine="Something went wrong"
        ></EmptyContainer>
        <base-button
          type="primary"
          class="btn__generate mt-3"
          :loading="generateLoading"
          :disabled="!task.assignee_id && user.role == 'user'"
          @click="generateContent()"
          >Try Again</base-button
        >
      </div>
    </div>

    <!-- result -->
    <div
      v-else
      class="orderResult w-100 richText"
      :class="
        order?.product_key == 'blog_post_paragraphs'
          ? 'blog_post_paragraph_editor'
          : ''
      "
    >
      <!-- Specific content component(s) -->
      <YoloBlogPostParagraphsFinalResult v-if="order?.content && order?.status == 'accepted' && order?.product_key == 'yolo_blog_post_paragraphs'" />
      <YoloCopySocialFinalResult v-else-if="order?.content && order?.status == 'accepted' && order?.product_key == 'yolo_copy_social'" />

      <!-- Dynamic content component -->
      <div v-else>
        <component
          v-if="
            (contentComponentName &&
              order &&
              order?.content &&
              order?.status == 'accepted') ||
            task
          "
          :is="contentComponentName"
          v-model="order.content"
          :disabled="task && task.assignee_id != user.id && user.role == 'user'"
        />

        <p
          v-else-if="
            (order && !order?.content) ||
            (order && order?.content && order?.status !== 'accepted')
          "
        >
          Nothing to show
        </p>
        <ParagraphSkeleton v-else />
        <BlogPostParagraphsImageSection
          v-if="order?.product_key == 'blog_post_paragraphs' && !savedImages"
          :order="order"
        />
        <RagAdditionalSource
          v-if="ragDocuments?.length > 0 || ragLinks?.length > 0"
          :documents="ragDocuments"
          :links="ragLinks"
        />
        <hr />
        <BlogPostParagraphsGallery
          v-if="savedImages.length > 0"
          :images="savedImages"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { updateOrder } from "@/services/ordersService";
import ParagraphSkeleton from "@/pages/Components/ParagraphSkeleton.vue";
import RichTextEditor from "../../../../components/Inputs/RichTextEditor.vue";
import SeoMetaTagAnalyzerContent from "./SeoMetaTagAnalyzerContent.vue";
import SeoPageAuthorityContent from "./SeoPageAuthorityContent.vue";
import SeoDomainAuthorityContent from "./SeoDomainAuthorityContent.vue";
import SeoKeywordDensityContent from "./SeoKeywordDensityContent.vue";
import AiChatContent from "./AiChatContent.vue";
import BlogPostParagraphsImageSection from "src/components/AiWriter/BlogPostParagraphs/BlogPostParagraphsImageSection.vue";
import EmptyContainer from "../../../Components/EmptyContainer.vue";
import BlogPostParagraphsGallery from "../../../../components/AiWriter/BlogPostParagraphs/BlogPostParagraphsGallery.vue";
import RagAdditionalSource from "../../../../components/Inputs/RagAdditionalSource.vue";
import YoloBlogPostParagraphsFinalResult from "../../../Tools/YoloBlogPostParagraphs/FinalResult.vue";
import YoloCopySocialFinalResult from "../../../Tools/YoloCopySocial/CopySocialFinalResult.vue";

export default {
  components: {
    ParagraphSkeleton,
    SeoMetaTagAnalyzerContent,
    SeoPageAuthorityContent,
    RichTextEditor,
    SeoDomainAuthorityContent,
    SeoKeywordDensityContent,
    AiChatContent,
    BlogPostParagraphsImageSection,
    EmptyContainer,
    BlogPostParagraphsGallery,
    RagAdditionalSource,
    YoloBlogPostParagraphsFinalResult,
    YoloCopySocialFinalResult,
  },

  data() {
    return {
      firstMount: true,
      generateLoading: false,
      content: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    order() {
      let order = this.$store.getters.getOrder;
      if (order?.product_key == "ai_amazon") {
        order.content = order.content
          .replace("<div>", "")
          .replace("</div>", "");
      }
      return order;
    },
    ragDocuments() {
      return (
        this.order?.json_data?.api_output?.retrieved_documents_metadata?.filter(
          (el) => {
            return el.url_type == "aws_url";
          },
        ) ?? []
      );
    },
    ragLinks() {
      return (
        this.order?.json_data?.api_output?.retrieved_documents_metadata?.filter(
          (el) => {
            return el.url_type == "standard_url";
          },
        ) ?? []
      );
    },
    task() {
      return this.$store.getters.getTask;
    },

    savedImages() {
      return this.order?.json_data?.saved_images ?? [];
    },
    contentComponentName() {
      let productKey;

      if (this.order?.product_key == "seo_metatags_analyzer") {
        productKey = "SeoMetatagsAnalyzer";
      } else if (this.order?.product_key == "seo_page_authority") {
        productKey = "SeoPageAuthority";
      } else if (this.order?.product_key == "seo_domain_authority") {
        productKey = "SeoDomainAuthority";
      } else if (this.order?.product_key == "seo_keyword_density") {
        productKey = "SeoKeywordDensity";
      } else if (this.order?.product_key == "ai_chat_conversational") {
        productKey = "AiChat";
      }

      if (productKey) {
        return `${productKey}Content`;
      }

      return `RichTextEditor`;
    },
  },
  watch: {
    "order.content"() {
      this.firstMount
        ? (this.firstMount = false)
        : this.$emit("contentChanged", true);
    },
  },

  methods: {
    async generateContent() {
      this.generateLoading = true;
      const response = await this.$store.dispatch("createOrder", {
        api_action: "generate",
        order_detail_id: this.order.order_detail_id,
        product_key: this.order.product_key,
      });

      await this.$store.dispatch("fetchOrder", {
        id: this.$route.params.order_detail_id,
      });
      this.$emit("contentChanged", false);
      if (this.task) {
        this.$store.dispatch("fetchTaskHistory", { task_id: this.task.id });
      }
      this.generateLoading = false;
    },

    async saveOrder() {
      let response;
      const textContent = this.content;

      this.$store.commit("setOrderContent", textContent);

      response = await updateOrder(
        this.order.order_detail_id,
        textContent,
        this.order?.title,
      );
    },
  },
};
</script>
