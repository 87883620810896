<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <!-- <h4 class="font-weight-bold">Add a New website</h4> -->
        <form @submit.prevent="handleSubmit(onSubmit)" class="mt-3">
            <span v-if="loading" class="loader"></span>
            <div>
                <div class="row">
                    <ValidationProvider rules="required" class="col-12">
                        <base-input :label="'Website name'" type="text" :placeholder="'Enter website name'" v-model="form.name">
                        </base-input>
                    </ValidationProvider>
                </div>
                <div class="row">
                    <ValidationProvider class="col-12">
                        <base-input :label="'Description'" :optional=true type="text"
                            :placeholder="'Write a short description'" v-model="form.desc">
                        </base-input>
                    </ValidationProvider>
                </div>
                <div class="row">
                    <ValidationProvider class="col-lg-12" rules="required">
                        <label>Service</label>
                        <el-select :popper-append-to-body="false" v-model="form.external_service" class="select-primary" size="medium">
                            <el-option v-for="service in external_services" :key="service.value" :label="service.label"
                                :value="service.value"></el-option>
                        </el-select>
                    </ValidationProvider>
                </div>
                <div class="row">
                    <ValidationProvider class="col-lg-6 pr-2" rules="required">
                        <label>Protocol</label>
                        <el-select :popper-append-to-body="false" v-model="form.protocol_mode" class="select-primary" size="medium">
                            <el-option v-for="protocol in protocols" :key="protocol.value" :label="protocol.label"
                                :value="protocol.value"></el-option>
                        </el-select>
                    </ValidationProvider>
                    <ValidationProvider class="col-lg-6 pl-2" rules="required">
                        <base-input :label="'Website URL'" type="text" :placeholder="'Enter website URL'"
                            v-model="form.host_url">
                        </base-input>
                    </ValidationProvider>
                </div>
                <div class="row" v-if="form.external_service == 'wordpress'">
                    <ValidationProvider class="col-lg-6 pr-2" rules="required">
                        <base-input :label="'Website Username'" type="text" :placeholder="'Enter website Username'"
                            v-model="form.host_username">
                        </base-input>
                    </ValidationProvider>
                    <ValidationProvider class="col-lg-6 pl-2" rules="required">
                        <base-input :label="'Website Password'" type="password" :placeholder="'Enter website Password'"
                            v-model="form.host_pw">
                        </base-input>
                    </ValidationProvider>
                </div>
                <div class="row" v-if="form.external_service == 'shopify'">
                    <ValidationProvider class="col-lg-6 pr-2" rules="required">
                        <base-input :label="'Api Token'" type="text" :placeholder="'Enter Api Token'"
                            v-model="form.api_token">
                        </base-input>
                    </ValidationProvider>
                    <ValidationProvider class="col-lg-6 pl-2" rules="required">
                        <base-input :label="'Api Key'" type="text" :placeholder="'Enter Api Key'"
                            v-model="form.api_key">
                        </base-input>
                    </ValidationProvider>
                </div>
                <base-button native-type="submit" type="primary" class="btn-fill btn_submit_form w-100 mt-2" :disabled="!isFilled || loading">
                    {{ updatingWebsite ? 'Edit Website' : 'Add Website' }}
                </base-button>
                <base-button @click.prevent="hideForm" type="primary"
                    class="btn-fill w-100 btn_add_link">
                    Cancel
                </base-button>

            </div>
        </form>
    </ValidationObserver>
</template>
<script>
import { Form, Select, Option } from 'element-ui';

export default {
    components: {
        [Form.name]: Form,
        [Select.name]: Select,
        [Option.name]: Option,
    },
    props: {
        updatingWebsite: Object
    },
    data() {
        return {
            loading: false,
            form: {
                name: "",
                desc: "",
                external_service: "wordpress",
                protocol_mode: "https://",
                host_url: "",
                host_username: "",
                host_pw: "",
                api_key: "",
                api_token: "",
            },
            protocols: [
                { label: 'https://', value: 'https://' },
                { label: 'http://', value: 'http://' },
            ],
            external_services: [
                { label: 'Wordpress', value: 'wordpress' },
                { label: 'Shopify', value: 'shopify' },
            ],
        };
    },
    computed: {
        isFilled() {
            return  this.form.name &&
                    this.form.protocol_mode &&
                    this.form.external_service &&
                    this.form.host_url &&
                    ((this.form.external_service == 'wordpress' && this.form.host_username && this.form.host_pw) ||
                     (this.form.external_service == 'shopify' && this.form.api_key && this.form.api_token))
                    ;
        }
    },
    methods: {
        hideForm() {
            this.$emit('hideForm');
        },

        getUrl(protocol_mode, host_url) {
            let url = host_url
            if (!host_url.startsWith('https://') && !host_url.startsWith('http://')) {
                url = protocol_mode + host_url
            }
            return url;

        },

        async onSubmit() {

            this.loading = true;

            let params = {
                ...this.form,
                'host_url': this.getUrl(this.form.protocol_mode, this.form.host_url),
                'host_password': this.form.host_pw
            }

            if (this.updatingWebsite) {
                await this.$store.dispatch('updateWebsite', params )
                    .then(() => { this.$emit('hideForm') })
                    .finally(() => { this.loading = false });
            }
            else {
                await this.$store.dispatch('createWebsite', params )
                    .then((response) => {
                        this.$emit('websiteCreated', response.data?.websiteId);
                        this.$emit('hideForm')
                    })
                    .finally(() => { this.loading = false });
            }
        }
    },

    mounted() {
        if (this.updatingWebsite) {
            this.form = { ...this.updatingWebsite };
            this.form.external_service = this.updatingWebsite.external_service_name;
            if (this.updatingWebsite.host_url.startsWith('https://')) {
                this.form.protocol_mode = 'https://';
                this.form.host_url = this.updatingWebsite.host_url.replace('https://','');
            } else if (this.updatingWebsite.host_url.startsWith('http://')) {
                this.form.protocol_mode = 'http://';
                this.form.host_url = this.updatingWebsite.host_url.replace('http://','');
            }
        }
  }
};

</script>
