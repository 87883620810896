<script>
import TabSwitchContainer from "@/components/UI/TabSwitchContainer.vue";
import Stack from "@/components/Containers/Stack.vue";
import { Select, Option, Message } from "element-ui";
import SuggestedLink from "@/components/Toolbars/LinkBuilder/SuggestedLink.vue";
import Link from "@/components/Toolbars/LinkBuilder/Link.vue";
import CustomLinkForm from "@/components/Toolbars/LinkBuilder/CustomLinkForm.vue";
import EmptyState from "@/components/UI/EmptyState.vue";
import LinkBuilderSettings from "@/components/Toolbars/LinkBuilder/LinkBuilderSettings.vue";
import Heading from "@/components/UI/Typography/Heading.vue";
import Paragraph from "@/components/UI/Typography/Paragraph.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "LinkBuilder",
  components: {
    Paragraph,
    Heading,
    LinkBuilderSettings,
    CustomLinkForm,
    EmptyState,
    SuggestedLink,
    Link,
    TabSwitchContainer,
    Stack,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      activeTab: "link-builder",
      activeLinks: [],
      customUrlFormToggle: false,
    };
  },
  computed: {
    ...mapGetters([
      "getCustomUrl",
      "getOccurrencesNumber",
      "anyError",
      "getSuggestedLinks",
      "getSuggestedLinksLoading",
      "getOrder",
      "getEditingArea",
      "getOccurrences",
      "selectedWorkspace",
      "workspaceLinkSources",
      "workspaceLinkSourcesLoading",
      "getActiveLinksInEditingArea",
    ]),
    suggestedLinks: {
      get() {
        return this.getSuggestedLinks;
      },
    },
  },
  methods: {
    ...mapActions(["setLink", "fetchWorkspaceLinkSources"]),
    ...mapMutations(["setOrder"]),
    updateOrderContent() {
      let newOrder = this.getOrder;
      newOrder.content = this.getEditingArea.innerHTML;
      this.setOrder(newOrder);
    },

    handleBuildLinks(customUrl) {
      this.setLink(customUrl.link);
      this.updateOrderContent();
      this.loadActiveLinks();
    },

    loadActiveLinks() {
      this.activeLinks = [];
      const links = this.getEditingArea.querySelectorAll("a");
      links.forEach((link) => {
        if (
          this.activeLinks.length > 0 &&
          this.activeLinks.find((activeLink) => activeLink.href === link.href)
        ) {
          this.activeLinks.find((activeLink) => activeLink.href === link.href)
            .occurrences++;
          return;
        }
        this.activeLinks.push({
          href: link.href,
          text: link.innerText,
          occurrences: 1,
        });
      });
    },

    handleEditLink(payload) {
      const linkList = this.getEditingArea.querySelectorAll(
        `a[href="${payload.url}"]`,
      );
      linkList.forEach((link) => {
        link.href = payload.editedLink;
      });
      this.activeLinks = this.activeLinks.map((link) => {
        if (link.href === payload.url) {
          link.href = payload.editedLink;
        }
        return link;
      });
      this.updateOrderContent();
      this.$forceUpdate();
    },

    handleDeleteLink(url) {
      const linkList = this.getEditingArea.querySelectorAll(`a[href="${url}"]`);
      linkList.forEach((link) => {
        link.outerHTML = link.innerHTML;
      });
      this.activeLinks = this.activeLinks.filter((link) => link.href !== url);
      this.updateOrderContent();
      this.$forceUpdate();
    },
  },
  async mounted() {
    try {
      await this.fetchWorkspaceLinkSources(this.selectedWorkspace.id);
      this.loadActiveLinks();
    } catch (e) {
      Message.error("An error occurred while fetching the link sources");
    }
  },
};
</script>

<template>
  <card class="counter-container" style="height: 100%">
    <div class="d-flex justify-content-between align-items-center mb-2 py-2">
      <Heading :level="3" class="mb-0 font-weight-bold">Link Builder</Heading>
      <button class="exit-button" @click="$emit('exit-link-builder')">
        <i class="fa-solid fa-times"></i>
      </button>
    </div>
    <TabSwitchContainer class="mb-4">
      <div class="button-container">
        <button
          @click="activeTab = 'link-builder'"
          class="button_switch_panel m-0"
          :class="
            activeTab === 'link-builder' ? 'button_switch_panel--selected' : ''
          "
        >
          {{ $t("linkBuilder.switch.link-builder") }}
        </button>
      </div>
      <div class="button-container">
        <button
          @click="activeTab = 'manage-links'"
          class="button_switch_panel m-0"
          :class="
            activeTab === 'manage-links' ? 'button_switch_panel--selected' : ''
          "
        >
          {{ $t("linkBuilder.switch.manage-links") }}
        </button>
      </div>
    </TabSwitchContainer>

    <Stack
      v-if="activeTab === 'link-builder'"
      v-loading="workspaceLinkSourcesLoading"
    >
      <div>
        <Heading :level="3" class="mb-2 font-weight-bold">
          {{ $t("linkBuilder.builder.title") }}
        </Heading>
        <Paragraph>{{ $t("linkBuilder.builder.description") }}</Paragraph>
      </div>

      <LinkBuilderSettings
        v-if="workspaceLinkSources.length > 0"
        :workspaceLinkSources="workspaceLinkSources"
      />

      <div v-else>
        <Paragraph>
          No source websites added yet.
          <router-link
            :to="{
              name: 'workspace.settings',
              params: { id: this.selectedWorkspace.id },
            }"
          >
            Add a source website
          </router-link>
          to get started.
        </Paragraph>
      </div>

      <div v-if="suggestedLinks.length > 0">
        <Heading :level="4" class="mb-2">
          Suggested links ({{ suggestedLinks.length }})
        </Heading>
        <Paragraph>
          Hyperlink your keywords with one of the options from the list below or
          <a @click="customUrlFormToggle = true">add a custom url</a>
        </Paragraph>
      </div>

      <CustomLinkForm
        v-if="customUrlFormToggle"
        @submit-custom-url="handleBuildLinks"
      />

      <EmptyState
        v-if="!suggestedLinks.length > 0"
        v-loading="getSuggestedLinksLoading"
        title="No results yet!"
        description="The AI's suggested links will appear here."
        iconUrl="/img/empty-comments-state.svg"
      />

      <Stack v-else>
        <SuggestedLink
          v-for="(link, index) in suggestedLinks"
          :key="index"
          :url="link.link"
          :title="link.title"
          :description="link.snippet"
          @click.native="handleBuildLinks(link)"
        />
      </Stack>
    </Stack>

    <Stack v-else-if="activeTab === 'manage-links'" gap="2rem">
      <div>
        <Heading :level="3" class="mb-2">Manage Links</Heading>
        <Paragraph>Here you can delete or modify your saved links</Paragraph>
      </div>
      <Stack v-if="activeLinks.length > 0">
        <Link
          v-for="link in activeLinks"
          :key="link.href"
          :word="link.text"
          :url="link.href"
          :occurrence="link.occurrences"
          @edit-link="handleEditLink"
          @delete-link="handleDeleteLink"
        />
      </Stack>
      <EmptyState
        v-else
        title="There’s nothing to see here...yet"
        description="When you add links to your document, they will appear here as a list."
        iconUrl="/img/empty-links-state.svg"
      />
    </Stack>
  </card>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.font-body-s > a {
  background: none;
  border: none;
  color: var(--blu-accent);
  cursor: pointer;
  text-decoration: underline;
  display: inline;
}

.button-container {
  display: flex;
  justify-content: center;
}

.exit-button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: white;
  border-radius: 200px;
  font-size: 12px;
  width: 24px;
  height: 24px;
}

.button_switch_panel {
  min-width: 90%;
}
</style>
