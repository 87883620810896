<script>
import { BaseInput } from '@/components/index';
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CustomLinkForm",
  components: {
    BaseInput
  },
  data() {
    return {
      urlError: false
    };
  },
  computed: {
    ...mapGetters([
      'getCustomUrl',
      'getErrors'
    ]),
    customUrl: {
      get() {
        return this.getCustomUrl;
      },
      set(value) {
        this.setCustomUrl(value);
      }
    }
  },
  methods: {
    ...mapMutations([
      'setCustomUrl'
    ])
  }
};
</script>

<template>
  <form @submit.prevent="$emit('submit-custom-url', customUrl); customUrl = null" class="custom-link-form">
    <label>{{$t('linkBuilder.builder.custom_url.label')}}</label>
    <div class="input_with_btn align-items-start">
      <BaseInput
        :placeholder="$t('linkBuilder.builder.custom_url.placeholder')"
        type="text"
        :optional="false"
        :error="getErrors.custom_url"
        v-model="customUrl"
        class="m-0"
      />
      <base-button
        icon
        size="sm"
        class="btn-simple mb-n1"
        native-type="submit"
        :disabled="getErrors.custom_url !== null"
      >
        <div>
          <i class="fa-solid fa-plus"></i>
        </div>
      </base-button>
    </div>
  </form>
</template>

<style scoped>

</style>
