<template>
  <card>
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <h4 slot="header" class="title mb-0">Member settings</h4>
        <p slot="header" class="subtitle mb-0">View and manage the members of your workspace</p>
      </div>
        <base-button @click="dialogFormVisible = true" native-type="submit" type="primary" :disabled="excludedUser.length === 0"
          class="btn-fill ml-3 my-0 py-3 px-3 btn_add_link">
          Add Member
        </base-button>
    </div>
    <WorkspaceMemberSettingsTable/>

    <!-- Form -->
    <el-dialog class="dialog-md" title="Add Members" :visible.sync="dialogFormVisible" >
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div>

            <div class="row">
                <div v-for="userSeat in excludedUser" :key="userSeat.user.id" class="workspace__add_user_list--item">
                    <div class="form-check">
                        <label class="form-check-label">
                            <input class="form-check-input" type="checkbox" v-model="form.users" :value="userSeat" />{{ userSeat.user.email }}
                            <span class="form-check-sign"></span>
                        </label>
                    </div>
                    <p class="col-3 px-0 text-center">{{ $t('seats.roles.'+userSeat.role) }}</p>
                </div>
            </div>

            <base-button @click="dialogFormVisible = false" native-type="submit" type="primary"
              class="btn-fill btn_submit_form w-100" :disabled="form.users.length == 0">
              Add
            </base-button>

          </div>
        </form>
      </ValidationObserver>
    </el-dialog>

  </card>
</template>

<script>
import WorkspaceMemberSettingsTable from './WorkspaceMemberSettingsTable.vue';
import { Dialog, Select, Option } from 'element-ui';

export default {
  components: {
    WorkspaceMemberSettingsTable,
    [Dialog.name]: Dialog,
    [Select.name]: Select,
    [Option.name]: Option,

  },
  data() {
    return {
      loading: false,
      dialogFormVisible: false,
      form: {
        users: []
      },
    };
  },
  computed: {
    excludedUser() {
      return this.$store.getters.excludedWorkspaceUsers
    },
    workspaceId() {
      return this.$route.params?.id ?? this.$store.getters.selectedWorkspace.id;
    }
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('addUserToWorkspace', { 
        'id': this.workspaceId, 
        'users': this.form.users 
      })
      .then(() => {
          this.$store.dispatch("fetchExcludedWorkspaceUsers", this.workspaceId);
          this.$store.dispatch("fetchWorkspaceUsers", this.workspaceId);
      })
    }
  },
  mounted() {
    this.$store.dispatch('fetchExcludedWorkspaceUsers', this.workspaceId)
  }
};
</script>
  