<script>
export default {
  name: "TabSwitchContainer",
};
</script>

<template>
  <div
    class="button_switch_panel-container px-0 py-2 mb-0"
  >
    <slot></slot>
  </div>
</template>

<style scoped>
.button_switch_panel-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
