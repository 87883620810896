<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { BaseInput } from "@/components/index.js";

export default {
  name: "LinkBuilderForm",
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseInput,
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <ValidationObserver v-slot="{ valid }" slim>
    <form @submit.prevent="$emit('submit-form', link)" class="row">
      <span v-if="loading" class="loader"></span>
      <ValidationProvider rules="required" class="col-12">
        <base-input
          :label="'Website name'"
          type="text"
          :placeholder="'Enter website name'"
          v-model="link.name"
        >
        </base-input>
      </ValidationProvider>
      <ValidationProvider class="col-12">
        <base-input
          :label="'Description'"
          :optional="true"
          type="text"
          :placeholder="'Write a short description'"
          v-model="link.description"
        >
        </base-input>
      </ValidationProvider>
      <ValidationProvider class="col-12" rules="required">
        <base-input
          :label="'Website URL'"
          type="text"
          :placeholder="'Enter website URL'"
          v-model="link.url"
        >
        </base-input>
      </ValidationProvider>
      <div class="col-12">
        <base-button
          native-type="submit"
          type="primary"
          class="btn-fill btn_submit_form w-100 mt-2"
          :disabled="!valid || loading"
        >
          {{ link.id ? "Edit Website" : "Add Website" }}
        </base-button>
        <base-button
          @click.prevent="$emit('exit-form')"
          type="primary"
          class="btn-fill w-100 btn_add_link"
        >
          Cancel
        </base-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<style scoped></style>
