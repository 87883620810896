<script>
export default {
  name: "EmptyState",
  props: {
    iconUrl: {
      type: String,
    },
    iconAlt: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<template>
  <div class="order__comments__empty_state my-lg">
    <div class="order__comments__empty_state--img">
      <img :src="iconUrl" :alt="iconAlt" v-if="iconUrl">
    </div>
    <h4>{{title}}</h4>
    <span>{{description}}</span>
  </div>
</template>

<style scoped>

</style>
