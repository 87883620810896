<script>
import Heading from "@/components/UI/Typography/Heading.vue";
import { BaseInput, BaseDropdown } from "@/components/index";
import Paragraph from "@/components/UI/Typography/Paragraph.vue";
import { Dialog } from "element-ui";

export default {
  name: "Link",
  components: {
    Paragraph,
    Heading,
    BaseInput,
    BaseDropdown,
    [Dialog.name]: Dialog,
  },
  props: {
    word: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    occurrence: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      editedLink: '',
      dialogVisible: false,
    };
  },
  methods: {
    editLink() {
      this.isEditing = false;
      this.$emit('edit-link', {
        url: this.url,
        editedLink: this.editedLink
      });
    },
    deleteLink() {
      this.$emit('delete-link', this.url)
    }
  },
  mounted() {
    this.editedLink = this.url;
  },
};
</script>

<template>
  <div class="link-container">
    <div class="link" v-if="!isEditing">
      <Heading :level="4">{{ word }} ({{ occurrence }})</Heading>
      <a :href="url" target="_blank">{{ url }}</a>
    </div>
    <div class="action" v-if="!isEditing">
      <base-dropdown
        class="nav-item d-flex justify-content-end"
        title-classes="dropdown-icon-button"
        menu-classes="dropdown-navbar dropdown-history dropdown__container_menu order__container_menu"
      >
        <template #title>
            <i class="fas fa-ellipsis-v"></i>
        </template>
        <li>
          <button class="dropdown-link-button" @click="isEditing = true">
            Edit Link
          </button>
        </li>
        <li>
          <button class="dropdown-link-button" @click="dialogVisible = true">
            Delete Link
          </button>
        </li>
      </base-dropdown>
    </div>
    <form v-else class="edit-link">
      <BaseInput
        :placeholder="$t('linkBuilder.builder.search.placeholder')"
        type="text"
        :optional="true"
        v-model="editedLink"
        class="mb-0"
        style="grid-column-start: span 2;"
      />
      <base-button simple @click="isEditing = false"> Cancel </base-button>
      <base-button @click="editLink"> Save </base-button>
    </form>
    <el-dialog
      :title="'Delete links?'"
      class="dialog-sm"
      :visible.sync="dialogVisible"
    >
      <Paragraph>
        This will delete all the links applied to the word <b> {{ word }} </b> in your doc.
      </Paragraph>
      <div class="row">
        <div class="col-12">
          <base-button
            native-type="submit"
            type="secondary"
            class="btn-fill btn_add_link w-100 d-block"
            @click="dialogVisible = false"
          >
            Cancel
          </base-button>
          <base-button
            @click="deleteLink"
            type="primary"
            class="btn-fill btn_submit_form w-100 d-block"
          >
            Delete
          </base-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">
.edit-link {
  grid-column: 1 / span 6;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}
.link {
  grid-column: 1 / span 5;
  max-width: 100%;
  & > a {
    display: inline;
    color: var(--blu-accent);
  }

  & > h4 {
    font-size: 12px;
    font-weight: bold;
    color: var(--blu-dark);
    margin-bottom: 0.2em;
  }

  &:hover {
    border-color: var(--blu-accent);
  }
}

.action {
  grid-column:  6 / span 1;
}

.link-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  cursor: pointer;
  border: 2px solid #d8d8d8;
  border-radius: 8px;
  padding: 0.8rem;
}
</style>
