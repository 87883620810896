<template>
  <div id="skeletonParagraph" class="skeleton-paragraph mb-5">
    <div v-if="showTitle" class="skeleton-title"></div>
    <div v-for="index in rows" :key="index" class="skeleton-line"></div>
  </div>
</template>

<script>
export default {
  props: {
    rows: {
      type: Number,
      default: 5,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    getRandomBetween(lowerLimit, upperLimit) {
      return Math.random() * (upperLimit - lowerLimit) + lowerLimit;
    },
    setWidth() {
      const parentElement = document.getElementById("skeletonParagraph");
      const lines = this.$el.querySelectorAll(".skeleton-line");
      const parentWidth = parentElement.clientWidth;

      lines.forEach((element) => {
        element.style.marginRight =
          parentWidth * this.getRandomBetween(0.1, 0.4) + "px";
      });
    },
  },
  mounted() {
    this.setWidth();
  },
};
</script>

<style scoped lang="scss">
.skeleton-paragraph {
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  & > div {
    border-radius: 9999px;
    background-color: #374151;
  }

  .skeleton-title {
    height: 10px;
    width: 50%;
    margin-bottom: 16px;
  }

  .skeleton-line {
    height: 8px;
  }
}

.white-content {
  .skeleton-paragraph {
    & > div {
      background-color: #e5e7eb;
    }
  }
}

@keyframes pulse {
  50% {
    opacity: 0.2;
  }
}
</style>
