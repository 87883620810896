<script>
export default {
  name: "Heading",
  props: {
    level: {
      type: Number,
      required: true,
    },
  },
};
</script>

<template>
  <component :is="'h' + level" :class="{
    'heading-1': level === 1,
    'heading-2': level === 2,
    'heading-3': level === 3,
    'heading-4': level === 4,
  }">
    <slot></slot>
  </component>
</template>

<style scoped>

</style>
