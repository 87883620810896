<template>
    <div>
        <div v-if="metadata">
            <h3 class="mb-0 mt-1 font-weight-bold">Meta title</h3>
            <p class="mb-4">{{ metadata.metatitle }}</p>

            <h3 class="mb-0 mt-1 font-weight-bold">Meta description</h3>
            <p class="mb-4">{{ metadata.metadescription }}</p>

            <h3 class="mb-0 mt-1 font-weight-bold">Meta keywords</h3>
            <p class="mb-4">{{ metadata.metakeywords?.join(', ') }}</p>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        order () {
            return this.$store.getters.getOrder
        },
        metadata () {
            return this.order.content?.blog_post_metadata ?? this.order.json_data?.api_output?.blog_post_metadata
        }
    }
}
</script>