<template>
  <div class="row mt-3 mb-5">
    <div class="col-12">
      <div>
        <div class="content">
          <div class="table-responsive table-text-to-image">
            <el-table
              v-if="additionalSources.length > 0"
              :data="additionalSources"
              @sort-change="handleSortChange"
            >
              <!-- Source Name -->
              <el-table-column
                min-width="200"
                prop="source"
                :label="$t('additionalSources.table.source')"
                sortable
              >
                <template #default="scope">
                  <label>{{ scope.row.source }}</label>
                </template>
              </el-table-column>

              <!-- Type -->
              <el-table-column
                min-width="200"
                prop="type"
                :label="$t('additionalSources.table.type')"
                sortable
              >
                <template #default="scope">
                  <span>{{ scope.row.type }}</span>
                </template>
              </el-table-column>

              <!-- Keywords -->
              <el-table-column
                min-width="300"
                prop="keywords"
                :label="$t('additionalSources.table.keywords')"
                sortable
              >
                <template #default="scope">
                  <div class="keywords-list">
                    <span
                      v-for="keyword in scope.row.keywords"
                      :key="keyword"
                      class="badge rounded-pill pill--primary"
                    >
                      {{ keyword }}
                    </span>
                  </div>
                </template>
              </el-table-column>

              <!-- On/Off -->
              <el-table-column
                :min-width="135"
                align="right"
                :label="'On/Off'"
                sortable
              >
                <template #default="scope">
                  <el-switch
                    v-model="scope.row.active"
                    @change="toggleSources(scope.row.uuid)"
                  ></el-switch>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "@/axios";
import { Table, TableColumn, Select, Option, Tooltip, Switch  } from "element-ui";

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Switch.name]: Switch,
  },
  data() {
    return {
      additionalSources: [],
      loading: false,
      error: false,
    };
  },
  methods: {
    async fetchData() {
      this.$preloader.fadeIn();
      this.loading = true;

      try {
        const user = this.$store.getters.getUser;
        const response = await axios.get("tools/additional_sources", {
          params: {
            user_id: user.id,
            workspace_id: this.workspaceId,
          },
        });

        this.additionalSources = response.data;
      } catch (error) {
        this.error = error.message;
        console.error(error);
      } finally {
        this.loading = false;
      }
      this.$preloader.fadeOut();
    },
    handleSortChange(column) {
      if (column.prop === "created") {
        this.additionalSources.sort((a, b) => {
          const dateA = new Date(a.created.split(" ").reverse().join("-"));
          const dateB = new Date(b.created.split(" ").reverse().join("-"));
          if (column.order === "descending") {
            return dateB - dateA;
          } else {
            return dateA - dateB;
          }
        });
      }
      if (column.prop === "updated") {
        this.additionalSources.sort((a, b) => {
          const dateA = new Date(a.updated.split(" ").reverse().join("-"));
          const dateB = new Date(b.updated.split(" ").reverse().join("-"));

          if (column.order === "descending") {
            return dateB - dateA;
          } else {
            return dateA - dateB;
          }
        });
      }
      if (column.prop === "keywords" && column.order) {
        this.additionalSources.sort((a, b) => {
          const keywordA = a.keywords[0] || "";
          const keywordB = b.keywords[0] || "";

          if (column.order === "ascending") {
            return keywordA.localeCompare(keywordB);
          } else {
            return keywordB.localeCompare(keywordA);
          }
        });
      }
      if (
        column.prop === "source" ||
        (column.prop === "type" && column.order)
      ) {
        this.additionalSources.sort((a, b) => {
          const valueA = a[column.prop];
          const valueB = b[column.prop];

          if (column.order === "ascending") {
            return valueA.localeCompare(valueB);
          } else {
            return valueB.localeCompare(valueA);
          }
        });
      }
      if (
        column.prop === "uuid" ||
        (column.prop === "type" && column.order)
      ) {
        this.additionalSources.sort((a, b) => {
          const valueA = a[column.prop];
          const valueB = b[column.prop];

          if (column.order === "ascending") {
            return valueA.localeCompare(valueB);
          } else {
            return valueB.localeCompare(valueA);
          }
        });
      }
    },
    toggleSources(additionalSourceId) {
      console.log(additionalSourceId);
      this.$store.dispatch("toggleSources", {
        id: this.workspaceId,
        additional_source_id: additionalSourceId,
      });
    },
  },
  mounted() {
    this.fetchData();
  },
  created() {
    this.$root.$on("refreshTable", this.fetchData);
  },
  beforeDestroy() {
    this.$root.$off("refreshTable", this.fetchData);
  },
  computed: {
    sortedAdditionalSources() {
      return this.additionalSources.slice().sort((a, b) => {
        const [dayA, monthA, yearA] = a.created.split(" ");
        const [dayB, monthB, yearB] = b.created.split(" ");

        const dateA = new Date(yearA, monthA - 1, dayA);
        const dateB = new Date(yearB, monthB - 1, dayB);

        return dateB - dateA;
      });
    },
    workspaceId() {
      return this.$route.params?.id ?? this.$store.getters.selectedWorkspace.id;
    },
  },
};
</script>

<style scoped lang="scss">
.pagination-select,
.search-input {
  width: 200px;
}

::v-deep .swal2-icon-content {
  font-size: inherit !important;
}
::v-deep .el-switch .el-switch__core {
  border-color: transparent;
  background: #dcdfe6;
  width: 44px !important;
  transition: all 0.3s;
}

::v-deep .el-switch.is-checked .el-switch__core {
  border-color: transparent;
  background: linear-gradient(
    107deg,
    #034a98 6.12%,
    #0e173f 44.98%,
    #321979 85.83%
  );
}
</style>
